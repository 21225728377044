//this file switches between Nav 3 and Nav 4 page-layout components. When Nav 4 is at 100% this file can simply be deleted and index can export nav 4 directly instead
import React from 'react';
import type { FC } from 'react';

import { fg } from '@confluence/feature-gating';
import { LoadablePaint } from '@confluence/loadable';

import type { PageLayoutProps } from './PageLayoutTypes';

const Nav3Layout = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav3-layout" */ './nav3/PageLayout')).PageLayout,
});

const Nav4Layout = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav4-layout" */ './PageLayout')).PageLayout,
});

export const PageLayout: FC<PageLayoutProps> = (props) => {
	return fg('confluence_nav_4') ? <Nav4Layout {...props} /> : <Nav3Layout {...props} />;
};
